import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { Button } from '@components/Button';
import Checkbox from '@components/Formik/Checkbox';
import PasswordInput from '@components/Formik/PasswordInput';
import { isValidKoreanRrnNumberSchema } from '@components/Formik/validation/fieldDefinitions';
import {
  isRequiredConfirmCheckbox,
  isRequiredConfirmRRN,
  schemaField,
} from '@components/Formik/validation/schemaDefinitions';
import Icon from '@components/Icon';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useAddRRN } from '@repositories/government-id';
import { useGetRRN } from '@repositories/government-id/hooks';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { constructFullName } from '@utils/constructFullName';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

export interface FormValues {
  rrnNumber: string;
  confirmRRNNumber: string;
}
const KoreaRRNForm: React.FC = () => {
  const { user } = useAppConfig();
  const validationSchema = (t: TFunction) =>
    yup.object().shape({
      rrnNumber: isValidKoreanRrnNumberSchema(t),
      confirmRRNNumber: schemaField(
        t,
        yup.string(),
        isRequiredConfirmRRN
      ).oneOf(
        [yup.ref('rrnNumber'), null],
        t(
          'online-giving.my-donations.korean-rrn.confirm-rrn-did-not-match]',
          'Please enter a matching Resident Registration Number (RRN)'
        )
      ),
      privacypolicy: schemaField(
        t,
        yup.bool(),
        isRequiredConfirmCheckbox
      ).oneOf([true], ' '),
    });
  const individualId = user?.individualId;
  const { data, loading, error } = useFetchProfileDetails(individualId || '');
  const { t } = useTranslation();
  const [AddRRN] = useAddRRN();
  const { addError, addSuccess } = useNotifications();
  const [state, setState] = useState({
    isRRNDisplay: false,
    isLoading: false,
  });
  const [errDisplay, setErrDisplay] = useState(false);
  const [stateRRN, setStateRRN] = useState({
    userRRNInfo: '',
  });
  useErrorHandling(
    t('view-profile.query.error', 'An error occurred.'),
    error !== undefined,
    'profile-view.error'
  );

  const netForumMemberId = data?.individual.riIndividualId || '';

  const { data: rrnDATA } = useGetRRN(netForumMemberId.toString());

  useEffect(() => {
    if (rrnDATA?.results?.RRN_c) {
      setStateRRN({ userRRNInfo: rrnDATA?.results.RRN_c });
      setState({ ...state, isRRNDisplay: true });
    }
  }, [rrnDATA]);

  if (error || !individualId) {
    return <p>error</p>;
  }

  if (!data || loading) {
    return <Loading />;
  }

  if (state.isLoading) {
    return <Loading />;
  }

  const {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    riIndividualId,
  } = data.individual;

  const fullName = constructFullName({
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  });

  const handleFormSubmit = async (values: FormValues) => {
    const response = await AddRRN({
      variables: {
        rrnId: values.rrnNumber,
        netForumMemberId: riIndividualId?.toString() || '',
        collectionDate: new Date().toISOString().split('T')[0],
      },
    });
    setState({ ...state, isLoading: true });

    if (response?.data?.addRRN?.rrn === 'Success') {
      addSuccess(
        t(
          'online-giving.my-donations.form.success-message',
          'Success! Your entry was saved'
        )
      );
      setState({ isLoading: false, isRRNDisplay: true });
      setStateRRN({ userRRNInfo: values.rrnNumber });
      setErrDisplay(false);
    } else {
      addError(
        t(
          'online-giving.my-donations.form.error-message',
          'Error. Entry was not saved, please try again.'
        )
      );
      setState({ ...state, isLoading: false });
      setErrDisplay(false);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={{
        rrnNumber: '',
        confirmRRNNumber: '',
        privacypolicy: false,
      }}
      validationSchema={validationSchema(t)}
    >
      {({ isSubmitting, resetForm, values, errors }) => {
        return (
          <Form>
            <LinkPrevious
              path="/my-donations"
              label={t(
                'online-giving.my-donations.back-link.label',
                'My Donations'
              )}
            />
            <Title>
              {t(
                'online-giving.my-donations.title',
                'Government Identification Number'
              )}
            </Title>
            <p className="text-big mb-2">
              {t(
                'online-giving.my-donations.korea-rrn-govt-notification',
                'Due to government regulations in Korea, donors giving in Korean won must provide their government identification number.'
              )}
            </p>
            <h2 className="mt-18">
              {t(
                'online-giving.my-donations.manage-korea-rrn-label',
                'Manage Korean Resident Registration Number (RRN)'
              )}
            </h2>
            <p>
              {t(
                'online-giving.my-donations.korea-rrn-notify-user',
                'You can add your Korean Resident Registration Number (RRN). To make changes or corrections to your Korean Resident Registration Number (RRN) as held in your Rotary records call the Korean Finance team at (82-2) 783-3077.'
              )}
            </p>
            <p className="text-big mb-2">{fullName}</p>
            {state.isRRNDisplay ? (
              <div className="w-min">
                <PasswordInput
                  name="rrnNumberReadonly"
                  value={stateRRN.userRRNInfo}
                  type="text"
                  passwordShowHide
                />
              </div>
            ) : (
              <div>
                <div className="w-1/2">
                  <PasswordInput
                    name="rrnNumber"
                    label={t(
                      'online-giving.my-donations.korea-rrn.label',
                      'Korean Resident Registration Number (RRN)'
                    )}
                    passwordShowHide
                    value={values.rrnNumber || ''}
                  />
                  <PasswordInput
                    name="confirmRRNNumber"
                    label={t(
                      'online-giving.my-donations.korea-rrn.confirm-label',
                      'Confirm your Korean Resident Registration Number (RRN)'
                    )}
                    passwordShowHide
                    value={values.confirmRRNNumber || ''}
                  />
                </div>
                <div className="pt-7">
                  <div className="flex m-auto mb-0 h-7">
                    <Checkbox
                      name="privacypolicy"
                      label={t(
                        'online-giving.my-donations.korea-rrn.privacy-policy-description',
                        `I confirm that I have read and agree to <a href="https://my.rotary.org/en/korean-rrn-and-personal-data-policy" target="_blank"  class="flex-col text-bright-blue-600 text-base mx-1 underline hover:no-underline focus-visible:outline-0 focus:shadow-none" >Korean RRN and Personal Data Policy</a>.`
                      )}
                    />
                  </div>
                  <div>
                    {errDisplay && errors.privacypolicy && (
                      <p
                        className={classNames(
                          'form-error mb-0 mt-0 text-xs text-red-600'
                        )}
                      >
                        {t(
                          'online-giving.my-donations.korea-rrn.privacy-policy-error',
                          'Confirmation needed.'
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div className="tablet:flex mt-16">
                  <Button
                    className="mb-5 tablet:mr-5 tablet:mb-0"
                    disabled={isSubmitting}
                    clickHandler={() => setErrDisplay(true)}
                  >
                    {t(
                      'online-giving.my-donations.form.save-button-label',
                      'Save'
                    )}
                  </Button>
                  <Button
                    type="button"
                    secondary
                    disabled={isSubmitting}
                    clickHandler={() => {
                      resetForm();
                      setErrDisplay(false);
                    }}
                  >
                    {t(
                      'online-giving.my-donations.form.cancel-button-label',
                      'Cancel'
                    )}
                  </Button>
                </div>
              </div>
            )}

            <div className="pt-48">
              <Icon
                name="squares/secure"
                color="white"
                size="20"
                className="m-auto block"
              />
              <p className="text-center m-5">
                {t(
                  'online-giving.my-donations.data-safety.korea-rrn',
                  'Your data is safe.'
                )}
              </p>
              <hr className="border-black" />
              <p className="mt-5">
                {t(
                  'online-giving.my-donations.privacy-policy.korea-rrn',
                  'Your privacy is important to Rotary. The personal data you share with Rotary will only be used for official Rotary business. This means that the personal data you provide will primarily be used for financial processing, supporting The Rotary Foundation, communicating key organizational messages and responding to your inquiries. Personal data collected on this form is subject to <a href="https://my.rotary.org/en/privacy-policy" target="_blank">Rotary’s Privacy Policy</a>.'
                )}
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default KoreaRRNForm;
